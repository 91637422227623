import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "./ServicesList.css";

import Email from "../../images/email.svg";
import Tele from "../../images/phone-call.svg";


function ServicesList(props) {
    const data = props;
    console.log(data)

    return (
        <div className=" lg:block w-full md:w-1/3 relative order-2 md:order-1">
            <div className=" sv-sticky-container pr-0 md:pr-8" style={{ top: "100px" }} >
                <div className="sc-navigation" >
                    {data.list.map((cat, index) => (
                        <div key={`nav-cat-${index}`} className={index > 0 ? "mt-8" : "mt-0"}>
                            <ul>
                                {cat.node.services && cat.node.services.edges.map((service, yndex) => (
                                    <li key={`nav-serv-${yndex}`} className="sv-li" style={data.currentPage === service.node.uri ? { backgroundColor: "#244989", color: "white" } : { color: "inherit" }}>
                                        <Link to={service.node.uri} >
                                            {service.node.title}
                                        </Link>
                                    </li>
                                ))}

                                {cat.node.examinations && cat.node.examinations.edges.map((service, yndex) => (
                                    <li key={`nav-serv-${yndex}`} className="sv-li" style={data.currentPage === service.node.uri ? { backgroundColor: "#244989", color: "white" } : { color: "inherit" }}>
                                        <Link to={service.node.uri} >
                                            {service.node.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {(data.helpBanner && (data?.promoBanner?.image === null)) && (
                    <div className="help-cont relative mt-8">
                        <GatsbyImage
                            image={data.helpBanner.serviceHelpBanner.image.imageFile.childImageSharp.gatsbyImageData}
                            alt={data.helpBanner.serviceHelpBanner.image.altText}
                            style={{ width: "100%", minHeight: "345px", opacity: "0.2", position: "absolute" }} />
                        <div
                            style={{ zIndex: 2 }}
                            className="help-in-c"
                        >
                            <div style={{ border: "3px solid #fff" }}>
                                <div className={"p-8"}>
                                    <div>
                                        <GatsbyImage
                                            image={data.helpBanner.serviceHelpBanner.icon.imageFile.childImageSharp.gatsbyImageData}
                                            alt={data.helpBanner.serviceHelpBanner.icon.altText}
                                            style={{ filter: "brightness(0) invert(1)" }} />
                                    </div>
                                    <div className="text-white">
                                        <p className="py-4" style={{ fontSize: "25px", lineHeight: "32px", color: "white", fontWeight: "600" }}>{data.helpBanner.serviceHelpBanner.title}</p>
                                        <div dangerouslySetInnerHTML={{ __html: data.helpBanner.serviceHelpBanner.content }} />
                                        <div className="relative" style={{ zIndex: "9" }}>
                                            <div className="pt-4">
                                                <a href={`tel:${data.contact.telephoneNumber}`} className="flex">
                                                    <div>
                                                        <img src={Tele} style={{ width: "20px", filter: "brightness(0) invert(1)" }} alt="Tele" />
                                                    </div>
                                                    <div className="pl-2">
                                                        {data.contact.telephoneNumber}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="pt-4">
                                                <a href={`mailto:${data.contact.supportEmail}`} className="flex">
                                                    <div>
                                                        <img src={Email} style={{ width: "20px", filter: "brightness(0) invert(1)" }} alt="email" />
                                                    </div>
                                                    <div className="pl-2">
                                                        {data.contact.supportEmail}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {(data.promoBanner && data.promoBanner.image) && (
                    <Link to="/covid-test-halkidiki/" className="help-cont relative mt-8">
                        <GatsbyImage
                            image={data.promoBanner.image.imageFile.childImageSharp.gatsbyImageData}
                            alt={data.promoBanner.image.altText}
                            style={{ width: "100%" }} />
                    </Link>
                )}
            </div>
        </div>
    );
}

export default ServicesList;