import React, { useState } from 'react'
import "./ServiceContent.css" ;
import { GatsbyImage } from "gatsby-plugin-image";

function ServiceContent(props) {
    console.log(props)
    const data = props;

    return (
        <div className=" w-full md:w-2/3 pb-4 order-1 md:order-2">
            <GatsbyImage
                image={props.featured.imageFile.childImageSharp.gatsbyImageData}
                alt={props.featured.altText}
                className="m-4" />
            <div className="p-4 service-editor" dangerouslySetInnerHTML={{__html: data.content }} />
        </div>
    );
}

export default ServiceContent;