import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
// import Introduction from "../components/Service/Introduction"
import ServiceContent from "../components/Service/ServiceContent"
import ServicesList from "../components/Service/ServicesList"
import Breadcrumb from "../components/Global/Breadcrumb"
import Faqs from "../components/Global/Faqs"



function ExaminationTemplate(props) {
    let data = props.data.wpgraphql;
    console.log(props)

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.examination.examinationCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}/${data.examination.examinationCategories.edges[0].node.breadcrumbServicesAcf.breadcrumbSlug}/`
        })
        bCrumb.push({
            text: data.examination.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://plusmedical.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://plusmedical.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts = { data.posts }
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData = {constructMetaData(data.examination, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                examinations={props.pageContext.examinationsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>
                    {/* <section>
                        {data.examination.servicesAcf.featuredImage && (
                            <Introduction backImg={data.examination.servicesAcf.featuredImage} title={data.examination.title} />
                        )}
                    </section> */}
                    {data.examination.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.examination.breadcrumbAcf.image} h1={data.examination.breadcrumbAcf.breadcrumbName} />
                        </section>
                    )}
                    <section className="container flex flex-wrap m-auto justify-center md:my-10" style={{maxWidth: "1170px"}}>
                        <ServicesList list={data.examinationCategories.edges} currentPage={data.examination.uri} helpBanner={data.helpBanner} contact={data.template.contactInformationTemplate} />
                        <ServiceContent content={data.examination.content} featured={data.examination.servicesAcf.featuredImage} />
                    </section>
                    <section>
                        {(data.examination.faqsAcf.faqs && data.examination.faqsAcf.faqs.faqs && data.examination.faqsAcf.faqs.faqs.length > 0) && (
                            <Faqs
                                data={data.examination.faqsAcf.faqs}
                            />
                        )}
                    </section>
                </div>
            </Layout>
        </div>

    )
}

export default ExaminationTemplate

export const pageQuery = graphql`query GET_EXAMINATION($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!, $helpBannerTemplateUri: ID!) {
  wpgraphql {
    examination(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      examinationCategories {
        edges {
          node {
            breadcrumbServicesAcf {
              breadcrumbName
              breadcrumbSlug
            }
          }
        }
      }
      title
      content
      uri
      servicesAcf {
        featuredImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
      faqsAcf {
        faqs {
          title
          faqs {
            question
            answer
            visibleInSite
          }
        }
      }
    }
    examinationCategories(where: {language: $language}) {
      edges {
        node {
          name
          examinations(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                id
                uri
                title
              }
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    helpBanner: template(id: $helpBannerTemplateUri, idType: SLUG) {
      serviceHelpBanner {
        title
        content
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 360
                quality: 80
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
        icon {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 38, quality: 100, placeholder: NONE, layout: FIXED)
            }
          }
        }
      }
    }
    posts(
      first: 2
      where: {language: $language, orderby: {field: DATE, order: ASC}}
    ) {
      ...RecentPostsFragment
    }
  }
}
`