import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'
import Home from "./../../images/home.svg"


import "./Breadcrumb.css";


function Breadcrumb(props) {
    const data = props;
    console.log(data)
    return (
        <div className="breadcrumb-container relative">
            <div style={{ display: "grid" }}>
                {props.image && (
                    <GatsbyImage
                        style={{
                            gridArea: "1/1",
                            maxHeight: "240px"
                        }}
                        alt={props.image ? props.image.altText : ""}
                        loading={"eager"}
                        image={props.image.imageFile.childImageSharp.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                    />
                )}

                <div
                    style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="bread-hh container m-auto py-6 flex flex-wrap items-center px-4">
                        <div style={{ backgroundColor:"#ffffff8f",padding: "1rem"}}>
                            <div className="w-full bread-h1">
                                <h1>{props.h1}</h1>
                            </div>
                            <div className="flex">
                                {data.list && data.list.map((dest, i) => {
                                    if (i < data.list.length - 1) {
                                        if (i === 0) {
                                            return (
                                                <React.Fragment>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer">
                                                        <img src={Home} alt="home-icon" style={{ width: "18px" }} />
                                                    </Link>
                                                    <span className="px-2">
                                                        -
                                    </span>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer flex md:hidden">
                                                        {dest.text.substring(0, 8) + '...'}
                                                    </Link>
                                                    <Link to={dest.url} key={`breadcrumb-${i}`} className="cursor-pointer hidden md:flex">
                                                        {dest.text}
                                                    </Link>
                                                    <span className="px-2">
                                                        -
                                    </span>
                                                </React.Fragment>
                                            )
                                        }
                                    } else {
                                        return (
                                            <span key={`breadcrumb-${i}`} style={{ color: "#244989" }} >
                                                { dest.text}
                                            </span>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Breadcrumb;